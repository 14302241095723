(function (root, factory) {
  // eslint-disable-next-line no-undef
  if (typeof define === 'function' && define.amd) {
    // eslint-disable-next-line no-undef
    define([ 'jquery' ], function (jquery) {
      if (!jquery.fn) jquery.fn = {};
      return factory(jquery);
    });
  } else if (typeof module === 'object' && module.exports) {
    var jQuery = (typeof window !== 'undefined') ? window.jQuery : undefined;
    if (!jQuery) {
      jQuery = require('jquery');
      if (!jQuery.fn) jQuery.fn = {};
    }
    module.exports = factory(jQuery);
  } else {
    root.drpAutoCompleteEnhanced = factory(root.jQuery);
  }
}(window, function ($) {
  var activeClass = 'active';

  var DrpAutoCompleteEnhanced = function (el, options) {
    this.options = options || {};
    this.currentIndex = -1;
    this.document = $(document);

    this.container = $(el);
    this.container.allItems = this.container.find('ul > li > a');

    this.container.itemNoResult = this.getItemNoResult();
    this.container.items = this.getItemsAbleToShow();

    this.container.inputText = this.container.find('input[type="text"]');
    this.container.dropdown = this.container.find('div[data-toggle="dropdown"]');
    this.container.dropdownContainer = this.container.find('.dropdown-menu');
    this.container.dropdownArrow = this.container.find('.js-drp-arrow');

    this.document.on('mouseup', $.proxy(this.handleToggleDropdown, this));
    this.container.on('keydown', $.proxy(this.handleKeyDown, this));
    this.container.on('show.bs.dropdown', $.proxy(this.handleEventDropdownShow, this));
    this.container.on('hidden.bs.dropdown', $.proxy(this.handleEventDropdownHidden, this));

    this.container.allItems.on('click', $.proxy(this.handleSelectedItem, this));

    this.container.inputText.on('input', $.proxy(this.handleInputChange, this));
    this.container.inputText.on('focus', $.proxy(this.handleInputFocus, this));
    this.container.inputText.on('blur, focusout', $.proxy(this.handleInputFocusOut, this));

    this.container.dropdown.on('click', $.proxy(this.handleClickOnDropdown, this));
    this.container.dropdownArrow.on('click', $.proxy(this.handleClickOnDropdownArrow, this));
  };

  DrpAutoCompleteEnhanced.prototype = {
    constructor: DrpAutoCompleteEnhanced,

    getItemNoResult: function () {
      return this.container.find('.js-drp-item-no-result');
    },

    getItemsAbleToShow: function () {
      return this.container.allItems.filter(function (index, item) {
        var isHidden = $(item).data('hidden');

        return !isHidden;
      }).not(this.container.itemNoResult);
    },

    getItemsVisible: function () {
      return this.container.allItems.filter(function (index, item) {
        return $(item).is(':visible');
      }).not(this.container.itemNoResult);
    },

    handleSelectedItem: function (e) {
      var target = $(e.target);

      var value = $(target).data('value');
      var text = $(target).data('text');

      this.container.inputText.val(text);
      this.container.inputText.data('value', value);
      this.container.inputText.change();

      this.container.items.parent().show();
      this.container.itemNoResult.parent().hide();
      this.container.trigger('selectedItem.drpAutoComplete', target);

      this.container.dropdown.dropdown('hide');

      e.stopPropagation();
    },

    handleInputChange: function (e) {
      var target = $(e.target);
      var searchValue = target.val().toLowerCase() || target.val();

      this.container.inputText.change();
      this.container.dropdown.dropdown('show');
      this.container.allItems.removeClass(activeClass);
      this.container.trigger('inputChange.drpAutoComplete', searchValue);

      var itemsAbleToShow = this.getItemsAbleToShow();

      if (!searchValue) {
        itemsAbleToShow.parent().show();
        this.container.itemNoResult.parent().hide();

        return;
      }

      var text;
      var itemsMatched = itemsAbleToShow.filter(function (index, item) {
        text = $(item).text().toLowerCase();
        return text.indexOf(searchValue) > -1;
      });

      itemsAbleToShow.parent().hide();
      itemsMatched.parent().show();

      if (!itemsMatched || !itemsMatched.length) {
        this.container.itemNoResult.parent().show();
      } else {
        this.container.itemNoResult.parent().hide();
      }
    },

    handleInputFocusOut: function (e) {
      e.stopImmediatePropagation();
    },

    handleInputFocus: function () {
      this.container.dropdown.dropdown('show');

      this.container.inputText.select();
    },

    handleClickOnDropdown: function (e) {
      this.container.inputText.focus();

      e.stopImmediatePropagation();
      e.preventDefault();
    },

    handleClickOnDropdownArrow: function (e) {
      if (this.isDropdownShown()) {
        this.container.dropdown.dropdown('hide');
      } else {
        this.container.inputText.focus();
      }

      e.stopImmediatePropagation();
      e.preventDefault();
    },

    handleToggleDropdown: function (e) {
      if (!this.container.is(e.target)
          && this.container.has(e.target).length === 0) {
        this.container.dropdown.dropdown('hide');
      }
    },

    handleEventDropdownShow: function () {
      this.container.allItems.removeClass(activeClass);

      this.getItemsAbleToShow().parent().show();
      this.getItemNoResult().parent().hide();

      this.currentIndex = -1;
    },

    handleEventDropdownHidden: function () {
      var value = this.container.inputText.val();
      var itemMatched = this.getMatchedItems(value);

      if (itemMatched && itemMatched.length) {
        this.container.trigger('selectedItem.drpAutoComplete', itemMatched.first());
        this.container.inputText.val(itemMatched.first().html());
      } else {
        this.container.inputText.val('').change();
        this.container.inputText.data('value', '');
      }
    },

    activeItemByIndex: function (items, index) {
      var activeItem = $(items[index]);
      var value = activeItem.data('value');
      var text = activeItem.data('text');

      $(items).removeClass(activeClass);
      activeItem.addClass(activeClass);

      var position = activeItem.position();
      var scrollTop = this.container.dropdownContainer.scrollTop() + position.top;

      this.container.dropdownContainer.scrollTop(scrollTop);

      this.container.inputText.val(text);
      this.container.inputText.data('value', value);
      this.container.inputText.change();

      if (value) {
        this.container.trigger('selectedItem.drpAutoComplete', [ activeItem, true ]);
      }
    },

    handleKeyDown: function (e) {
      var keyCode = e.which || e.keyCode;
      if (keyCode !== 38 && keyCode !== 40 && keyCode !== 13) {
        return;
      }

      var items = this.getItemsVisible();
      if (!items || !items.length) {
        items = [ this.container.itemNoResult ];
      }

      var lastIndex = items.length - 1;

      switch (e.which || e.keyCode) {
      case 38:
        this.currentIndex = this.currentIndex - 1;
        if (this.currentIndex < 0) {
          this.currentIndex = lastIndex;
        }

        this.activeItemByIndex(items, this.currentIndex);

        break;

      case 40:
        this.currentIndex = this.currentIndex + 1;
        if (this.currentIndex > lastIndex) {
          this.currentIndex = 0;
        }

        this.activeItemByIndex(items, this.currentIndex);

        break;

      case 13:
        this.container.dropdown.dropdown('hide');
        this.container.dropdownContainer.scrollTop(0);
        this.container.inputText.blur();

        if (items.length === 1) {
          this.activeItemByIndex(items, 0);
        }

        if (this.currentIndex >= 0) {
          this.container.trigger('selectedItem.drpAutoComplete', items[this.currentIndex]);
        }

        break;

      default:

      }
    },

    isDropdownShown: function () {
      return this.container.hasClass('show');
    },

    getMatchedItems: function (value) {
      var items = this.getItemsAbleToShow();

      var text;
      return items.filter(function (index, item) {
        text = $(item).text().toLowerCase();

        return text === value.toLowerCase();
      });
    }
  };

  $.fn.drpAutoCompleteEnhanced = function (options) {
    new DrpAutoCompleteEnhanced($(this), options);

    return $(this);
  };

  return DrpAutoCompleteEnhanced;
}));
